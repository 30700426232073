<i18n>
{
  "en": {
    "title": "Account Information",
    "description": "Select the plan that you want to subscribe and define an access domain.",
    "input-1": "Coupon",
    "input-2": "Domain",
    "optional": "Optional",
    "obs": "This will be the login link:",
    "btn": "Next",
    "step": "Step 3 of 3"
  },
  "pt-BR": {
    "title": "Dados da Conta",
    "description": "Selecione abaixo o plano o qual você deseja assinar e defina um domínio de acesso.",
    "input-1": "Cupom",
    "input-2": "Domínio",
    "optional": "Opcional",
    "obs": "Esse será o link de login:",
    "btn": "Próximo",
    "step": "Etapa 3 de 3"
  }
}
</i18n>

<template>
  <div class="step third">
    <v-form
      ref="thirdStep"
      v-model="valid"
      lazy-validation
      class="step third"
      @submit.prevent="nextStep"
    >
      <h2>{{ $t("title") }}</h2>
      <p>
        {{ $t("description") }}
      </p>
      <we-input
        v-model="payload.coupon"
        type="text"
        mask="XXXXXXXXXXXXXXX"
        :label="`${$t('input-1')} (${$t('optional')})`"
        :placeholder="$t('input-1')"
        :hide-details="false"
      />
      <we-input
        v-model="payload.domain"
        :rules="domainRules"
        type="text"
        mask="aaaaaaaaaaaaaaa"
        :label="$t('input-2')"
        :placeholder="$t('input-2')"
        :hide-details="false"
        is-required
        cy="create-account-domain"
      />
      <div class="login-link">
        <div class="obs">{{ $t("obs") }}</div>
        <div class="link">
          {{ location }}<b>{{ payload.domain }}</b
          >/login
        </div>
      </div>
    </v-form>
    <we-button
      class="success fill"
      :text="$t('btn')"
      icon="chevron-right"
      icon-position="right"
      :loading="loading || buttonLoading"
      :disabled="!valid"
      cy="create-account-third-next"
      @click="nextStep"
    />
    <div class="current-step">{{ $t("step") }}</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      location: "",
      buttonLoading: false,
      payload: {
        coupon: null,
        domain: null
      }
    };
  },
  computed: {
    domainRules() {
      return [
        v => v?.length >= 4 || "O domínio deve ter no mínimo 4 caracteres"
      ];
    },
    queryParams() {
      return this.$store.getters.getQueryParams;
    }
  },
  mounted() {
    this.location = process.env.VUE_APP_APP;

    if (this.queryParams?.coupon?.length > 0) {
      this.payload.coupon = this.queryParams.coupon;
    }
  },
  methods: {
    ...mapActions(["validateDomain"]),
    async nextStep() {
      this.buttonLoading = true;
      const validated = this.$refs.thirdStep.validate();

      if (validated) {
        const allowedDomain = await this.validateDomain(this.payload.domain);

        if (allowedDomain) {
          this.$emit("nextStep", this.payload);
        }

        this.buttonLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login-link {
  margin-bottom: 22px;
  .obs {
    font-size: 14px;
    color: var(--text-2);
  }

  .link {
    font-size: 14px;
    color: var(--text-1);

    b {
      font-weight: $medium;
      color: var(--primary);
    }
  }
}
</style>
