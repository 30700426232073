<i18n>
{
  "en": {
    "title": "Company Responsible",
    "description": "Fill in the fields below with the data of the responsible for this subscription.",
    "input-1": "Full name",
    "input-2": "Cellphone number",
    "btn": "Next",
    "step": "Step 1 of 3"
  },
  "pt-BR": {
    "title": "Dados do Responsável",
    "description": "Preencha os campos abaixo com os dados do responsável pela assinatura do wepipe.",
    "input-1": "Nome completo",
    "input-2": "Número de celular",
    "btn": "Próximo",
    "step": "Etapa 1 de 3"
  }
}
</i18n>

<template>
  <div class="step first">
    <v-form
      ref="firstStep"
      v-model="valid"
      lazy-validation
      class="step first"
      @submit.prevent="nextStep"
    >
      <h2>{{ $t("title") }}</h2>
      <p>
        {{ $t("description") }}
      </p>
      <we-input
        v-model="fullName"
        type="full-name"
        :label="$t('input-1')"
        :placeholder="$t('input-1')"
        :hide-details="false"
        is-required
        cy="create-account-name"
        @input="setLeadName"
      />
      <we-input
        v-model="payload.phone"
        type="text"
        :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
        :label="$t('input-2')"
        :placeholder="$t('input-2')"
        :hide-details="false"
        is-required
        cy="create-account-telefone"
      />
    </v-form>
    <we-button
      class="success fill"
      :text="$t('btn')"
      icon="chevron-right"
      icon-position="right"
      :loading="loading"
      :disabled="!valid"
      cy="create-account-first-next"
      @click="nextStep"
    />
    <div class="current-step">{{ $t("step") }}</div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      fullName: "",
      payload: {
        first_name: "",
        last_name: "",
        phone: ""
      }
    };
  },
  methods: {
    nextStep() {
      const validated = this.$refs.firstStep.validate();

      if (validated) {
        this.$emit("nextStep", this.payload);
      }
    },
    setLeadName(val) {
      const nameArr = val.trim().split(" ");

      if (nameArr.length > 1) {
        this.payload.first_name = nameArr.shift();
        this.payload.last_name = nameArr.join(" ");
      }
    }
  }
};
</script>

<style></style>
