<i18n>
{
  "en": {
    "not-found": "Workflow not found",
    "not-found-description-1": "To create an external form you need to configure a workflow,",
    "not-found-description-2": "click here to create",
    "title": "New Form",
    "create": "Next step"
  },
  "pt-BR": {
    "not-found": "Nenhum pipeline encontrado",
    "not-found-description-1": "Para criar um formulário web você precisa configurar um pipeline,",
    "not-found-description-2": "clique aqui para criar",
    "title": "Novo Formulário",
    "create": "Avançar"
  }
}
</i18n>

<template>
  <modal-action
    class="create-returns"
    @close="closeCreateReturnsForm"
    :columns="1"
  >
    <template #view>
      <v-form
        class="modal"
        :class="{ missing: pipelines.length === 0 }"
        ref="form"
        @submit.prevent="createReturnsForm"
      >
        <we-not-found v-if="pipelines.length === 0">
          <template #title> {{ $t("not-found") }} </template>
          <template #description>
            {{ $t("not-found-description-1") }}
            <router-link
              :to="{ name: 'pipelinesConfigs' }"
              class="green--text"
              active-class="active"
            >
              {{ $t("not-found-description-2") }}
            </router-link>
          </template>
        </we-not-found>
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="plus" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeCreateReturnsForm" />
          </div>
        </div>
        <div class="modal-body">
          <integration v-model="integration" v-if="step === 0" />
          <configuration v-model="configuration" v-if="step === 1" />
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('create')"
            icon="arrow-right"
            icon-position="right"
            :loading="btnLoading"
            :disabled="pipelines.length === 0 || !authenticated"
            cy="create-returns-form"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </v-form>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
import Integration from "@/views/Admin/Plugins/Returns/Edit/Integration";
import Configuration from "@/views/Admin/Plugins/Returns/Edit/Configuration";
export default {
  components: {
    Integration,
    Configuration
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      step: 0,
      returnsForm: {},
      integration: {
        platform: "Shopify",
        platform_store_id: "",
        platform_access_token: "",
        is_platform_connected: false
      },
      configuration: {
        initial_pipeline_stages: [],
        domain: ""
      },
      shipping: {
        is_store_return_enabled: true,
        is_postoffice_return_enabled: true,
        is_home_collection_return_enabled: true
      },
      chargeback: {
        is_cash_refund_enabled: true,
        is_credit_refund_enabled: true
      }
    };
  },
  computed: {
    authenticated() {
      return this.integration.is_platform_connected;
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    allStagesWithPipelinesList() {
      let items = [];

      this.pipelines.forEach(pipeline => {
        pipeline?.stages.forEach(stage => {
          items.push({
            text: `${stage.name} (${pipeline.name})`,
            value: stage.id
          });
        });
      });

      return items;
    }
  },
  methods: {
    ...mapActions(["createReturnsFormRequest", "pipelinesRequest"]),
    closeCreateReturnsForm() {
      this.$router.push({
        name: "returns"
      });
    },
    async createReturnsForm() {
      const validated = this.$refs.form.validate();
      if (validated) {
        if (this.authenticated) {
          if (this.step === 0) {
            this.btnLoading = true;
            this.step++;
            this.btnLoading = false;
          } else if (this.step === 1) {
            this.btnLoading = true;
            const response = await this.createReturnsFormRequest({
              ...this.integration,
              ...this.configuration,
              ...this.shipping,
              ...this.chargeback
            });
            this.$router.push({
              name: "editReturnsForm",
              params: { id: response.id }
            });
            this.btnLoading = false;
          }
        }
      }
    },
    async doRequests() {
      this.loading = true;
      await this.pipelinesRequest();
      this.loading = false;
    }
  },
  mounted() {
    this.doRequests();
  }
};
</script>

<style lang="scss" scoped>
.modal.missing {
  .modal-body {
    min-height: 250px;
  }
}

.we-not-found {
  padding-top: 75px;
}

#action_modal.create-returns {
  .view {
    .modal {
      width: 600px;
    }
  }
}
</style>
