<i18n>
{
  "en": {
    "title": "Registered with success!",
    "description-1": "Your password will be sent to your email",
    "description-2": ", remember to check your trash or spam box."
  },
  "pt-BR": {
    "title": "Cadastro realizado com sucesso!",
    "description-1": "A sua senha chegará no email",
    "description-2": ", verifique também na lixeira ou na caixa de spam."
  }
}
</i18n>

<template>
  <div class="step final">
    <h2>{{ $t("title") }}</h2>
    <p>
      {{ $t("description-1") }} <b>{{ lead.email }}</b>
      {{ $t("description-2") }}
    </p>
    <img
      class="undraw"
      src="@/assets/undraw/email-sent.svg"
      width="138"
      height="124"
      :alt="$t('title')"
    />
  </div>
</template>

<script>
export default {
  computed: {
    lead() {
      return this.$store.getters.getLead;
    }
  }
};
</script>

<style></style>
