var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"returns_shipping"}},[(_vm.newAddressOpened)?_c('return-address',{attrs:{"value":_vm.currentAddress},on:{"input":_vm.addAddress,"close":function($event){_vm.newAddressOpened = false}}}):_vm._e(),_c('we-block',{staticClass:"toggle",attrs:{"active":_vm.payload.is_postoffice_return_enabled,"title":_vm.$t('send-back'),"description":_vm.$t('send-back-description')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{attrs:{"label":_vm.payload.is_postoffice_return_enabled
            ? _vm.$t('shipping-enabled')
            : _vm.$t('shipping-disabled'),"hide-details":"","cy":"returns-postoffice-enable"},model:{value:(_vm.payload.is_postoffice_return_enabled),callback:function ($$v) {_vm.$set(_vm.payload, "is_postoffice_return_enabled", $$v)},expression:"payload.is_postoffice_return_enabled"}})]},proxy:true},{key:"sections",fn:function(){return [_c('div',{staticClass:"block-section"},[_c('div',{staticClass:"block-section grid d-none"},[_c('we-input',{attrs:{"type":"select","label":_vm.$t('shipping-improvement'),"items":[_vm.$t('best-for-customer'), _vm.$t('best-for-store')]}}),_c('we-input',{attrs:{"type":"money","label":_vm.$t('shipping-fee')}})],1),_c('div',{staticClass:"block-section"},[_c('we-editor',{attrs:{"label":_vm.$t('description')},model:{value:(_vm.payload.postoffice_return_details),callback:function ($$v) {_vm.$set(_vm.payload, "postoffice_return_details", $$v)},expression:"payload.postoffice_return_details"}})],1)])]},proxy:true}])}),_c('we-block',{staticClass:"toggle",attrs:{"active":_vm.payload.is_store_return_enabled,"title":_vm.$t('drop-off'),"description":_vm.$t('drop-off-description')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{attrs:{"label":_vm.payload.is_store_return_enabled
            ? _vm.$t('shipping-enabled')
            : _vm.$t('shipping-disabled'),"hide-details":"","cy":"returns-store-enable"},model:{value:(_vm.payload.is_store_return_enabled),callback:function ($$v) {_vm.$set(_vm.payload, "is_store_return_enabled", $$v)},expression:"payload.is_store_return_enabled"}})]},proxy:true},{key:"sections",fn:function(){return [_c('div',{staticClass:"block-section"},[_c('div',{staticClass:"block-section mb-1"},[_c('we-input',{attrs:{"type":"div","placeholder":_vm.$t('store-addresses')}})],1),(_vm.payload.store_address && _vm.payload.store_address.length > 0)?_c('div',{staticClass:"block-section mb-1"},_vm._l((_vm.payload.store_address),function(storeAddress,i){return _c('div',{key:i},[(_vm.editAddressOpened)?_c('return-address',{attrs:{"value":_vm.currentAddress,"deleteEnabled":true},on:{"input":_vm.editAddress,"close":function($event){_vm.editAddressOpened = false},"deleteAddress":_vm.deleteAddress}}):_vm._e(),_c('we-data-field',{staticClass:"edit",attrs:{"icon":"globe-americas","label":_vm.$t('address'),"value":((storeAddress.street) + ", " + (storeAddress.number) + ", " + (storeAddress.note) + " - " + (storeAddress.zipcode))},on:{"click":function($event){_vm.editAddressOpened = true;
                _vm.currentAddress = storeAddress;
                _vm.currentAddressIndex = i;}}})],1)}),0):_vm._e(),_c('div',{staticClass:"block-section"},[_c('we-data-field',{staticClass:"edit",attrs:{"icon":"globe-americas","label":_vm.$t('address'),"value":_vm.$t('new-address'),"cy":"returns-add-address"},on:{"click":function($event){_vm.newAddressOpened = true;
              _vm.currentAddress = _vm.newAddress;}}})],1),_c('div',{staticClass:"block-section"},[_c('we-editor',{attrs:{"label":_vm.$t('description')},model:{value:(_vm.payload.store_return_details),callback:function ($$v) {_vm.$set(_vm.payload, "store_return_details", $$v)},expression:"payload.store_return_details"}})],1)])]},proxy:true}])}),_c('we-block',{staticClass:"toggle",attrs:{"active":_vm.payload.is_home_collection_return_enabled,"title":_vm.$t('home-collection-back'),"description":_vm.$t('home-collection-back-description')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{attrs:{"label":_vm.payload.is_home_collection_return_enabled
            ? _vm.$t('shipping-enabled')
            : _vm.$t('shipping-disabled'),"hide-details":"","cy":"returns-home-collection-enable"},model:{value:(_vm.payload.is_home_collection_return_enabled),callback:function ($$v) {_vm.$set(_vm.payload, "is_home_collection_return_enabled", $$v)},expression:"payload.is_home_collection_return_enabled"}})]},proxy:true},{key:"sections",fn:function(){return [_c('div',{staticClass:"block-section"},[_c('div',{staticClass:"block-section"},[_c('we-editor',{attrs:{"label":_vm.$t('description')},model:{value:(_vm.payload.home_collection_return_details),callback:function ($$v) {_vm.$set(_vm.payload, "home_collection_return_details", $$v)},expression:"payload.home_collection_return_details"}})],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }