<i18n>
{
  "en": {
    "platform": "Platform",
    "platform-description": "Select the platform that you want to integrate with.",
    "auth": "Authentication",
    "auth-description": "Get the ecommerce or order management system credentials.",
    "auth-token": "Authentication Token",
    "auth-id": "Store ID",
    "auth-integration": "Integration status",
    "auth-click": "Check the integration",
    "integrated": "Already integrated with your store",
    "synced": "Products synced with your store",
    "sync-products": "Sync products",
    "sync": "Sync status",
    "click-to-sync": "Click to get products from"
  },
  "pt-BR": {
    "platform": "Plataforma",
    "platform-description": "Selecione a plataforma de e-commerce que deseja integrar.",
    "auth": "Autenticação",
    "auth-description": "Insira as credenciais de acesso à API da sua plataforma de e-commerce.",
    "auth-token": "Token de Autenticação",
    "auth-id": "ID da Loja",
    "auth-integration": "Status de integração",
    "auth-click": "Clique para fazer a integração",
    "integrated": "Formulário integrado!",
    "synced": "Produtos sincronizados!",
    "sync-products": "Sincronizar produtos",
    "sync": "Status da sincronização",
    "click-to-sync": "Clique para trazer os produtos do"
  }
}
</i18n>

<template>
  <section id="returns_integration">
    <we-block :title="$t('platform')" :description="$t('platform-description')">
      <template #sections>
        <div class="block-section flex">
          <div
            class="flex-item"
            v-for="platform in platforms"
            :key="platform.name"
            :class="{
              active:
                payload.platform.toLowerCase() === platform.name.toLowerCase()
            }"
            @click="payload.platform = platform.name"
          >
            <div class="picture">
              <img
                :src="require(`@/assets/ecommerce/${platform.logo}.svg`)"
                :alt="platform.name"
              />
            </div>
            <div class="text">
              {{ platform.name }}
            </div>
          </div>
        </div>
      </template>
    </we-block>
    <we-block :title="$t('auth')" :description="$t('auth-description')">
      <template #sections>
        <div class="block-section grid">
          <we-input
            v-model="payload.platform_access_token"
            type="text"
            :label="$t('auth-token')"
            cy="returns-auth-token"
          />
          <we-input
            v-model="payload.platform_store_id"
            type="text"
            :label="$t('auth-id')"
            cy="returns-store-id"
          />
        </div>
        <div class="block-section">
          <we-card-icon
            @click="authenticate"
            class="button"
            :color="payload.is_platform_connected ? 'green' : ''"
            :icon="payload.is_platform_connected ? 'unlock-keyhole' : 'lock'"
            :label="$t('auth-integration')"
            :title="
              payload.is_platform_connected
                ? $t('integrated')
                : $t('auth-click')
            "
            :loading="authLoading"
            cy="returns-auth-status"
          />
        </div>
        <div class="block-section mb-1" v-if="payload.is_platform_connected">
          <we-input type="div" :placeholder="$t('sync-products')" />
        </div>
        <div class="block-section" v-if="payload.is_platform_connected">
          <we-card-icon
            @click="synchronize"
            class="button"
            :icon="synced ? 'check' : 'cubes'"
            :color="synced ? 'green' : ''"
            :loading="syncLoading"
            :label="$t('sync')"
            :title="
              synced
                ? $t('synced')
                : `${$t('click-to-sync')} ${payload.platform}`
            "
            cy="returns-sync-products"
          />
        </div>
      </template>
      <!-- <template #bottom>
        <div class="bottom-text">
          {{ $t("auth-not-working") }}
        </div>
        <div class="bottom-action">
          <we-button class="primary" :text="$t('auth-tutorial')" icon="play" />
        </div>
      </template> -->
    </we-block>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    value: Object
  },
  data() {
    return {
      payload: this.value,
      authLoading: false,
      syncLoading: false,
      synced: false
    };
  },
  watch: {
    payload: {
      handler() {
        this.handleInput();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    platforms() {
      return [
        {
          name: "Shopify",
          logo: "shopify"
        },
        {
          name: "Magento",
          logo: "magento"
        },
        {
          name: "VTEX",
          logo: "vtex"
        }
      ];
    }
  },
  methods: {
    ...mapActions(["returnsPlatformAuthRequest", "returnsSyncProductsRequest"]),
    handleInput() {
      this.$emit("input", this.payload);
    },
    async authenticate() {
      this.authLoading = true;
      const auth = await this.returnsPlatformAuthRequest(this.payload);

      if (auth.status === 200) {
        this.payload.is_platform_connected = true;
        this.handleInput();
      }

      this.authLoading = false;
    },
    async synchronize() {
      this.syncLoading = true;
      const sync = await this.returnsSyncProductsRequest(this.payload);

      if (sync?.status === 200) {
        this.synced = true;
      }
      this.syncLoading = false;
    }
  }
};
</script>

<style lang="scss"></style>
