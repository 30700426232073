<i18n>
{
  "en": {
    "title": "Returns Forms",
    "create": "New form",
    "list": "Forms List",
    "not-found-title": "You didn't create any form yet",
    "not-found-description": "Click on the button below to create a new one"
  },
  "pt-BR": {
    "title": "Formulários de Devolução",
    "create": "Novo formulário",
    "list": "Lista de Formulários",
    "not-found-title": "Você não criou nenhum formulário de devolução",
    "not-found-description": "Clique no botão abaixo para criar"
  }
}
</i18n>

<template>
  <div id="returns-configs">
    <nav-top returnPage="configurations" :title="$t('title')">
      <template #top-content>
        <we-button
          class="success"
          :text="$t('create')"
          icon="plus"
          @click="$router.push({ name: 'createReturnsForm' })"
          cy="create-returns-form-cta"
        />
      </template>
    </nav-top>
    <div class="board">
      <div class="returns-grid">
        <router-view />
        <div class="content">
          <div class="we-title">
            {{ $t("list") }}
          </div>
          <div class="returns-list">
            <we-not-found v-if="returns.length === 0">
              <template #title>
                {{ $t("not-found-title") }}
              </template>
              <template #description>
                {{ $t("not-found-description") }}
              </template>
              <template #actions>
                <we-button
                  class="success"
                  :text="$t('create')"
                  icon="plus"
                  @click="$router.push({ name: 'createReturnsForm' })"
                />
              </template>
            </we-not-found>
            <div
              class="item"
              v-for="form in returns"
              :key="form.id"
              @click="
                $router.push({
                  name: 'editReturnsForm',
                  params: { id: form.id }
                })
              "
            >
              <div class="name">
                <span class="domain" @click.stop="openReturnsForm(form.domain)">
                  <font-awesome-icon icon="link" />
                  /{{ form.domain }}
                </span>
                <we-text-tooltip :text="form.title" />
              </div>
            </div>
          </div>
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: true,
      loadingBtn: false
    };
  },
  computed: {
    returns() {
      return this.$store.getters.getReturns;
    },
    domain() {
      return this.$store.getters.getDomain;
    }
  },
  methods: {
    ...mapActions(["returnsRequest", "deleteReturnsFormRequest"]),
    async doRequests() {
      await this.returnsRequest();
      this.loading = false;
    },
    async deleteReturnsForm(id) {
      this.loading = true;
      await this.deleteReturnsFormRequest(id);
      await this.doRequests();
    },
    openReturnsForm(formDomain) {
      window.open(
        process.env.VUE_APP_RETURNS_URL + this.domain + "/" + formDomain,
        "_blank"
      );
    }
  },
  mounted() {
    this.doRequests();
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name.includes("create") || from.name.includes("edit")) {
      this.loading = true;
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#returns-configs {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;

  .board {
    background: var(--background-2);
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .returns-grid {
    max-width: 767px;
    height: fit-content;
    margin: 0 auto;
    padding: 69px 50px;

    .content {
      .we-title {
        text-align: center;
        font-size: 16px;
        font-weight: $semibold;
        color: var(--text-2);
        margin-bottom: 18px;
      }

      .returns-list {
        min-height: 320px;
        position: relative;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 12px;
          padding-right: 4px;
          margin-bottom: 12px;
          border-radius: 10px;
          min-height: 50px;
          background: var(--background-1);
          border: 1px solid var(--line);
          cursor: pointer;

          &:hover {
            border: 1px solid var(--primary);
          }

          .name {
            font-size: 16px;
            font-weight: $medium;
            color: var(--text-1);
            display: flex;
            align-items: center;
            width: 100%;

            .domain {
              color: var(--primary);
              font-weight: $medium;
              margin-right: 12px;
              display: flex;
              align-items: center;
              cursor: pointer;

              &:hover {
                color: var(--primary-hover);

                svg {
                  color: var(--primary-hover);
                }
              }

              svg {
                margin-right: 6px;
                color: var(--primary);
              }
            }
          }
        }
      }
    }
  }
}
</style>
