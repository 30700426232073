<i18n>
{
  "en": {
    "title": "Company Information",
    "description": "Fill the fields below with the information of the company that will use wepipe.",
    "input-1": "Company name",
    "input-2": "Market",
    "input-3": "Department",
    "input-4": "Number of users",
    "input-4-tooltip": "Are considered users the employees who will have access on wepipe.",
    "btn": "Next",
    "step": "Step 2 of 3",
    "department-1": "Customer Service",
    "department-2": "Product Development",
    "department-3": "Financial and Shopping",
    "department-4": "Legal",
    "department-5": "Logistics",
    "department-6": "Marketing",
    "department-7": "Human Resources",
    "department-8": "Supplies",
    "department-9": "Information Technology",
    "department-10": "Sales",
    "department-11": "Other",
    "employee-1": "1 to 10 users",
    "employee-2": "10 to 20 users",
    "employee-3": "More than 20 users",
    "market-1": "Food",
    "market-2": "Construction",
    "market-3": "Education",
    "market-4": "Eletronics",
    "market-5": "Entertainment",
    "market-6": "Financial",
    "market-7": "Governmental",
    "market-8": "Properties",
    "market-9": "Industry",
    "market-10": "Insurance",
    "market-11": "Technology",
    "market-12": "Tourism",
    "market-13": "Retail",
    "market-14": "Other"
  },
  "pt-BR": {
    "title": "Dados da Empresa",
    "description": "Preencha os campos abaixo os dados da empresa onde o wepipe será implantado.",
    "input-1": "Nome da empresa",
    "input-2": "Mercado de atuação",
    "input-3": "Departamento",
    "input-4": "Total de usuários",
    "input-4-tooltip": "São considerados usuários os colaboradores da empresa que terão uma credencial de acesso na wepipe.",
    "btn": "Próximo",
    "step": "Etapa 2 de 3",
    "department-1": "Atendimento ao Cliente",
    "department-2": "Desenvolvimento de Produto",
    "department-3": "Financeiro e Compras",
    "department-4": "Jurídico",
    "department-5": "Logística",
    "department-6": "Marketing",
    "department-7": "Recursos Humanos",
    "department-8": "Suprimentos",
    "department-9": "Tecnologia da Informação",
    "department-10": "Vendas",
    "department-11": "Outro",
    "employee-1": "1 a 10 usuários",
    "employee-2": "10 a 20 usuários",
    "employee-3": "Mais de 20 usuários",
    "market-1": "Alimentação",
    "market-2": "Construção",
    "market-3": "Educação",
    "market-4": "Eletrônicos",
    "market-5": "Entretenimento",
    "market-6": "Financeiro",
    "market-7": "Governamental",
    "market-8": "Imóveis",
    "market-9": "Indústria",
    "market-10": "Seguros",
    "market-11": "Tecnologia",
    "market-12": "Turismo",
    "market-13": "Varejo",
    "market-14": "Outro"
  }
}
</i18n>

<template>
  <div class="step second">
    <v-form
      ref="secondStep"
      v-model="valid"
      lazy-validation
      class="step second"
      @submit.prevent="nextStep"
    >
      <h2>{{ $t("title") }}</h2>
      <p>
        {{ $t("description") }}
      </p>
      <we-input
        v-model="payload.company"
        type="text"
        :label="$t('input-1')"
        :placeholder="$t('input-1')"
        :hide-details="false"
        is-required
        cy="create-account-company"
      />
      <we-input
        v-model="payload.market"
        class="fill-input"
        type="global-select"
        :label="$t('input-2')"
        :placeholder="$t('input-2')"
        :items="marketList"
        :hide-details="false"
        is-required
        cy="create-account-market"
      />
      <we-input
        v-model="payload.department"
        class="fill-input"
        type="global-select"
        :label="$t('input-3')"
        :placeholder="$t('input-3')"
        :hide-details="false"
        is-required
        :items="departmentList"
        cy="create-account-department"
      />
      <we-input
        v-model="payload.total_employees"
        class="fill-input"
        type="global-select"
        :label="$t('input-4')"
        :placeholder="$t('input-4')"
        :hide-details="false"
        is-required
        :items="employeesList"
        :tooltip="$t('input-4-tooltip')"
        cy="create-account-total"
      />
    </v-form>
    <we-button
      class="success fill"
      :text="$t('btn')"
      icon="chevron-right"
      icon-position="right"
      :loading="loading"
      :disabled="!valid"
      :hide-details="false"
      cy="create-account-second-next"
      @click="nextStep"
    />
    <div class="current-step">{{ $t("step") }}</div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      fullName: "",
      payload: {
        company: "",
        department: "",
        market: "",
        total_employees: ""
      },
      departmentList: [
        {
          text: this.$t("department-1"),
          value: "Customer Service"
        },
        {
          text: this.$t("department-2"),
          value: "Product Development"
        },
        {
          text: this.$t("department-3"),
          value: "Financial and Shopping"
        },
        { text: this.$t("department-4"), value: "Legal" },
        { text: this.$t("department-5"), value: "Logistics" },
        { text: this.$t("department-6"), value: "Marketing" },
        { text: this.$t("department-7"), value: "Human Resources" },
        { text: this.$t("department-8"), value: "Supplies" },
        {
          text: this.$t("department-9"),
          value: "Information Technology"
        },
        { text: this.$t("department-10"), value: "Sales" },
        { text: this.$t("department-11"), value: "Other" }
      ],
      employeesList: [
        { text: this.$t("employee-1"), value: "1 a 10 usuários" },
        { text: this.$t("employee-2"), value: "10 a 20 usuários" },
        { text: this.$t("employee-3"), value: "Mais de 20 usuários" }
      ],
      marketList: [
        {
          text: this.$t("market-1"),
          value: "Food"
        },
        {
          text: this.$t("market-2"),
          value: "Construction"
        },
        {
          text: this.$t("market-3"),
          value: "Education"
        },
        { text: this.$t("market-4"), value: "Eletronics" },
        { text: this.$t("market-5"), value: "Entertainment" },
        { text: this.$t("market-6"), value: "Financial" },
        { text: this.$t("market-7"), value: "Governmental" },
        { text: this.$t("market-8"), value: "Properties" },
        {
          text: this.$t("market-9"),
          value: "Industry"
        },
        { text: this.$t("market-10"), value: "Insurance" },
        { text: this.$t("market-11"), value: "Technology" },
        { text: this.$t("market-12"), value: "Tourism" },
        { text: this.$t("market-13"), value: "Retail" },
        { text: this.$t("market-14"), value: "Other" }
      ]
    };
  },
  methods: {
    nextStep() {
      const validated = this.$refs.secondStep.validate();

      if (validated) {
        this.$emit("nextStep", this.payload);
      }
    }
  }
};
</script>

<style></style>
