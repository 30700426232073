<i18n>
{
  "en": {
    "title": "Return Portal",
    "update": "Save updates",
    "external-link": "Open returns customer page",
    "integration": "Integration",
    "configuration": "Configuração",
    "items": "Items",
    "shipping": "Shipping",
    "chargeback": "Chargeback",
    "customization": "Customization",
    "delete-title": "Delete form",
    "not-integrated": "This form is not integrated!",
    "generate-token": "Please provide a new API auth token and do the integration again."
  },
  "pt-BR": {
    "title": "Devoluções",
    "update": "Salvar alterações",
    "external-link": "Abrir formulário de devolução",
    "integration": "Integração",
    "configuration": "Configuração",
    "items": "Produtos",
    "shipping": "Devolução",
    "chargeback": "Estorno",
    "customization": "Customização",
    "delete-title": "Deletar formulário",
    "not-integrated": "O formulário não está integrado!",
    "generate-token": "Gere um novo token de autenticação da API e faça a integração novamente."
  }
}
</i18n>

<template>
  <div id="returns">
    <nav-top returnPage="returns" :title="$t('title')">
      <template #top-content>
        <we-button
          @click="update"
          class="success"
          :text="$t('update')"
          icon="check"
          :loading="loadingButton"
          cy="save-returns-form"
        />
        <we-icon-button
          icon="external-link-alt"
          direction="bottom"
          :name="$t('external-link')"
          @click="openReturnsForm"
        />
        <confirm-modal
          :open="confirmDeleteOpened"
          @close="confirmDeleteOpened = false"
          @confirm="deleteReturnsform"
        />
        <we-icon-button
          class="error--text"
          :icon="['far', 'trash-alt']"
          :name="$t('delete-title')"
          direction="bottom"
          @click="confirmDeleteOpened = true"
          cy="delete-returns-form"
        />
      </template>
    </nav-top>
    <board-plugin
      @selectMenu="menuActiveIndex = $event"
      :activeMenu="menuActiveIndex"
      :menu="menu"
    >
      <template #content>
        <integration
          v-model="integration"
          :key="renderKey"
          v-if="menuActiveIndex === 0"
        />
        <configuration
          v-model="configuration"
          :key="renderKey"
          v-if="menuActiveIndex === 1"
        />
        <items v-model="items" :key="renderKey" v-if="menuActiveIndex === 2" />
        <shipping
          v-model="shipping"
          :key="renderKey"
          v-if="menuActiveIndex === 3"
        />
        <chargeback
          v-model="chargeback"
          :key="renderKey"
          v-if="menuActiveIndex === 4"
        />
        <customization
          v-model="customization"
          :key="renderKey"
          v-if="menuActiveIndex === 5"
          @updateImages="updateComponents"
        />
      </template>
    </board-plugin>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Integration from "@/views/Admin/Plugins/Returns/Edit/Integration";
import Configuration from "@/views/Admin/Plugins/Returns/Edit/Configuration";
import Items from "@/views/Admin/Plugins/Returns/Edit/Items";
import Shipping from "@/views/Admin/Plugins/Returns/Edit/Shipping";
import Chargeback from "@/views/Admin/Plugins/Returns/Edit/Chargeback";
import Customization from "@/views/Admin/Plugins/Returns/Edit/Customization";
export default {
  components: {
    Integration,
    Configuration,
    Items,
    Shipping,
    Chargeback,
    Customization
  },
  data() {
    return {
      loadingButton: false,
      menuActiveIndex: 0,
      confirmDeleteOpened: false,
      renderKey: 0,
      integration: {
        platform: "",
        platform_store_id: "",
        platform_access_token: "",
        is_platform_connected: false
      },
      configuration: {
        initial_pipeline_stages: [],
        domain: ""
      },
      items: {
        return_reasons: []
      },
      shipping: {
        is_store_return_enabled: false,
        is_postoffice_return_enabled: false,
        is_home_collection_return_enabled: false,
        store_address: [],
        store_return_details: "",
        postoffice_return_details: "",
        home_collection_return_details: ""
      },
      chargeback: {
        is_cash_refund_enabled: true,
        cash_refund_details: "",
        is_credit_refund_enabled: true,
        is_instant_credit_enabled: true,
        is_special_offer_enabled: false,
        special_offer_limit: null,
        special_offer_percentage: null,
        credit_refund_details: ""
      },
      customization: {
        favicon: "",
        brand: "",
        store_url: "",
        terms_of_service: "",
        privacy_policies: "",
        help_center_url: "",
        google_analytics: ""
      }
    };
  },
  computed: {
    menu() {
      return [
        {
          name: this.$t("integration"),
          icon: "code-branch"
        },
        {
          name: this.$t("configuration"),
          icon: "cog"
        },
        {
          name: this.$t("items"),
          icon: "cubes"
        },
        {
          name: this.$t("shipping"),
          icon: "truck"
        },
        {
          name: this.$t("chargeback"),
          icon: ["far", "credit-card"]
        },
        {
          name: this.$t("customization"),
          icon: "brush"
        }
      ];
    },
    domain() {
      return this.$store.getters.getDomain;
    },
    returnsForm() {
      return this.$store.getters.getReturnsForm;
    }
  },
  methods: {
    ...mapActions([
      "updateReturnsFormRequest",
      "returnsFormRequest",
      "deleteReturnsFormRequest"
    ]),
    async update() {
      this.loadingButton = true;
      const form = {
        ...this.returnsForm,
        ...this.integration,
        ...this.configuration,
        ...this.items,
        ...this.shipping,
        ...this.chargeback,
        ...this.customization
      };
      await this.updateReturnsFormRequest(form);
      this.loadingButton = false;
    },
    async deleteReturnsform() {
      await this.deleteReturnsFormRequest(this.returnsForm);
      this.$router.push({
        name: "returns"
      });
    },
    openReturnsForm() {
      window.open(
        process.env.VUE_APP_RETURNS_URL +
          this.domain +
          "/" +
          this.returnsForm.domain,
        "_blank"
      );
    },
    updateInputs(object) {
      Object.keys(object).forEach(key => {
        object[key] = this.returnsForm[key];
      });
    },
    updateComponents() {
      this.updateInputs(this.integration);
      this.updateInputs(this.configuration);
      this.updateInputs(this.items);
      this.updateInputs(this.shipping);
      this.updateInputs(this.chargeback);
      this.updateInputs(this.customization);
      this.renderKey += 1;
    }
  },
  async mounted() {
    await this.returnsFormRequest(this.$route.params.id);

    if (!this.returnsForm.is_platform_connected) {
      const snackBar = {
        title: this.$t("not-integrated"),
        description: this.$t("generate-token"),
        status: "error"
      };

      this.$store.commit("setSnackBar", snackBar);
      this.$store.commit("addSnackBar");
    }
    this.updateComponents();
  }
};
</script>

<style lang="scss">
#returns {
  display: flex;
  height: 100vh;
  flex-flow: column;
}
</style>
