<i18n>
{
  "en": {
    "title": "Register now!",
    "description": "Forever, without credit card.",
    "input-1": "Insert your email",
    "btn": "Start For Free",
    "obs-1": "By creating account you agree to our",
    "obs-2": "Terms of Service",
    "obs-3": "e",
    "obs-4": "Privacy Policy.",
    "terms": "https://www.wepipe.com.br/docs/termos-e-condicoes-gerais-de-uso.pdf",
    "privacy": "https://www.wepipe.com.br/docs/politica-de-privacidade.pdf"
  },
  "pt-BR": {
    "title": "Comece a usar agora!",
    "description": "Para sempre, sem cartão de crédito.",
    "input-1": "Insira seu email",
    "btn": "Teste Grátis",
    "obs-1": "Ao se cadastrar você concorda com nossos",
    "obs-2": "Termos e Condições",
    "obs-3": "e",
    "obs-4": "Políticas de Privacidade.",
    "terms": "https://www.wepipe.com.br/docs/termos-e-condicoes-gerais-de-uso.pdf",
    "privacy": "https://www.wepipe.com.br/docs/politica-de-privacidade.pdf"
  }
}
</i18n>

<template>
  <div class="step lead">
    <v-form
      ref="leadStep"
      v-model="valid"
      lazy-validation
      @submit.prevent="nextStep"
    >
      <h2>{{ $t("title") }}</h2>
      <p>{{ $t("description") }}</p>
      <we-input
        v-model="payload.email"
        type="email"
        label="Email"
        :placeholder="$t('input-1')"
        :hide-details="false"
        is-required
        cy="create-account-email"
      />
    </v-form>
    <we-button
      class="success fill"
      :text="$t('btn')"
      :loading="loading"
      :disabled="!valid"
      cy="create-account-start"
      @click="nextStep"
    />
    <div class="obs">
      {{ $t("obs-1") }}
      <a :href="$t('terms')" target="_blank">
        {{ $t("obs-2") }}
      </a>
      {{ $t("obs-3") }}
      <a :href="$t('privacy')" target="_blank">
        {{ $t("obs-4") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      payload: {
        email: ""
      }
    };
  },
  computed: {
    queryParams() {
      return this.$store.getters.getQueryParams;
    },
    utms() {
      return this.$store.getters.getUtms;
    }
  },
  methods: {
    nextStep() {
      const validated = this.$refs.leadStep.validate();
      if (validated) {
        this.$emit("nextStep", { ...this.payload, ...this.utms });
      }
    }
  }
};
</script>

<style></style>
