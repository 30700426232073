var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"returns_integration"}},[_c('we-block',{attrs:{"title":_vm.$t('platform'),"description":_vm.$t('platform-description')},scopedSlots:_vm._u([{key:"sections",fn:function(){return [_c('div',{staticClass:"block-section flex"},_vm._l((_vm.platforms),function(platform){return _c('div',{key:platform.name,staticClass:"flex-item",class:{
            active:
              _vm.payload.platform.toLowerCase() === platform.name.toLowerCase()
          },on:{"click":function($event){_vm.payload.platform = platform.name}}},[_c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":require(("@/assets/ecommerce/" + (platform.logo) + ".svg")),"alt":platform.name}})]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(platform.name)+" ")])])}),0)]},proxy:true}])}),_c('we-block',{attrs:{"title":_vm.$t('auth'),"description":_vm.$t('auth-description')},scopedSlots:_vm._u([{key:"sections",fn:function(){return [_c('div',{staticClass:"block-section grid"},[_c('we-input',{attrs:{"type":"text","label":_vm.$t('auth-token'),"cy":"returns-auth-token"},model:{value:(_vm.payload.platform_access_token),callback:function ($$v) {_vm.$set(_vm.payload, "platform_access_token", $$v)},expression:"payload.platform_access_token"}}),_c('we-input',{attrs:{"type":"text","label":_vm.$t('auth-id'),"cy":"returns-store-id"},model:{value:(_vm.payload.platform_store_id),callback:function ($$v) {_vm.$set(_vm.payload, "platform_store_id", $$v)},expression:"payload.platform_store_id"}})],1),_c('div',{staticClass:"block-section"},[_c('we-card-icon',{staticClass:"button",attrs:{"color":_vm.payload.is_platform_connected ? 'green' : '',"icon":_vm.payload.is_platform_connected ? 'unlock-keyhole' : 'lock',"label":_vm.$t('auth-integration'),"title":_vm.payload.is_platform_connected
              ? _vm.$t('integrated')
              : _vm.$t('auth-click'),"loading":_vm.authLoading,"cy":"returns-auth-status"},on:{"click":_vm.authenticate}})],1),(_vm.payload.is_platform_connected)?_c('div',{staticClass:"block-section mb-1"},[_c('we-input',{attrs:{"type":"div","placeholder":_vm.$t('sync-products')}})],1):_vm._e(),(_vm.payload.is_platform_connected)?_c('div',{staticClass:"block-section"},[_c('we-card-icon',{staticClass:"button",attrs:{"icon":_vm.synced ? 'check' : 'cubes',"color":_vm.synced ? 'green' : '',"loading":_vm.syncLoading,"label":_vm.$t('sync'),"title":_vm.synced
              ? _vm.$t('synced')
              : ((_vm.$t('click-to-sync')) + " " + (_vm.payload.platform)),"cy":"returns-sync-products"},on:{"click":_vm.synchronize}})],1):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }