<i18n>
{
  "en": {
    "shipping-enabled": "Shipping method enabled!",
    "shipping-disabled": "Enable shipping method",
    "send-back": "Send it back",
    "send-back-description": "Allow customers to send the product back to the store by a post service.",
    "store-addresses": "STORE ADDRESSES",
    "address": "Address",
    "shipping-improvement": "Shipping improvement",
    "best-for-customer": "Best for customer",
    "best-for-store": "Best for my store",
    "shipping-fee": "Shipping fee",
    "description": "Description",
    "drop-off": "Drop off in a store",
    "drop-off-description": "Allow customers to drop off the product in a physical store.",
    "new-address": "Click to register a new store address",
    "home-collection-back": "Home collection",
    "home-collection-back-description": "Customers will have to wait shipping company come to their houses to collect the products."
  },
  "pt-BR": {
    "shipping-enabled": "Método de devolução habilitado!",
    "shipping-disabled": "Habilitar método de devolução",
    "send-back": "Postar pelo correios",
    "send-back-description": "Permite que o cliente devolva o produto postando pelo correios.",
    "store-addresses": "Endereços da loja",
    "address": "Endereço",
    "shipping-improvement": "Melhoria da devolução",
    "best-for-customer": "Melhor para o cliente",
    "best-for-store": "Melhor para minha loja",
    "shipping-fee": "Taxa de devolução",
    "description": "Descrição",
    "drop-off": "Deixar na loja",
    "drop-off-description": "Permite que o cliente devolva o produto na loja física.",
    "new-address": "Clique para cadastrar um novo endereço da loja",
    "home-collection-back": "Coleta em casa",
    "home-collection-back-description": "Permite que os clientes esperem a transportadora em casa para devolver os produtos."
  }
}
</i18n>

<template>
  <section id="returns_shipping">
    <return-address
      :value="currentAddress"
      @input="addAddress"
      v-if="newAddressOpened"
      @close="newAddressOpened = false"
    />
    <we-block
      :active="payload.is_postoffice_return_enabled"
      class="toggle"
      :title="$t('send-back')"
      :description="$t('send-back-description')"
    >
      <template #top>
        <v-switch
          v-model="payload.is_postoffice_return_enabled"
          :label="
            payload.is_postoffice_return_enabled
              ? $t('shipping-enabled')
              : $t('shipping-disabled')
          "
          hide-details
          cy="returns-postoffice-enable"
        />
      </template>
      <template #sections>
        <div class="block-section">
          <div class="block-section grid d-none">
            <we-input
              type="select"
              :label="$t('shipping-improvement')"
              :items="[$t('best-for-customer'), $t('best-for-store')]"
            />
            <we-input type="money" :label="$t('shipping-fee')" />
          </div>
          <div class="block-section">
            <we-editor
              v-model="payload.postoffice_return_details"
              :label="$t('description')"
            />
          </div>
        </div>
      </template>
    </we-block>
    <we-block
      :active="payload.is_store_return_enabled"
      class="toggle"
      :title="$t('drop-off')"
      :description="$t('drop-off-description')"
    >
      <template #top>
        <v-switch
          v-model="payload.is_store_return_enabled"
          :label="
            payload.is_store_return_enabled
              ? $t('shipping-enabled')
              : $t('shipping-disabled')
          "
          hide-details
          cy="returns-store-enable"
        />
      </template>
      <template #sections>
        <div class="block-section">
          <div class="block-section mb-1">
            <we-input type="div" :placeholder="$t('store-addresses')" />
          </div>
          <div
            v-if="payload.store_address && payload.store_address.length > 0"
            class="block-section mb-1"
          >
            <div v-for="(storeAddress, i) in payload.store_address" :key="i">
              <return-address
                :value="currentAddress"
                @input="editAddress"
                v-if="editAddressOpened"
                @close="editAddressOpened = false"
                :deleteEnabled="true"
                @deleteAddress="deleteAddress"
              />
              <we-data-field
                @click="
                  editAddressOpened = true;
                  currentAddress = storeAddress;
                  currentAddressIndex = i;
                "
                class="edit"
                icon="globe-americas"
                :label="$t('address')"
                :value="
                  `${storeAddress.street}, ${storeAddress.number}, ${storeAddress.note} - ${storeAddress.zipcode}`
                "
              />
            </div>
          </div>
          <div class="block-section">
            <we-data-field
              @click="
                newAddressOpened = true;
                currentAddress = newAddress;
              "
              class="edit"
              icon="globe-americas"
              :label="$t('address')"
              :value="$t('new-address')"
              cy="returns-add-address"
            />
          </div>
          <div class="block-section">
            <we-editor
              v-model="payload.store_return_details"
              :label="$t('description')"
            />
          </div>
        </div>
      </template>
    </we-block>
    <we-block
      :active="payload.is_home_collection_return_enabled"
      class="toggle"
      :title="$t('home-collection-back')"
      :description="$t('home-collection-back-description')"
    >
      <template #top>
        <v-switch
          v-model="payload.is_home_collection_return_enabled"
          :label="
            payload.is_home_collection_return_enabled
              ? $t('shipping-enabled')
              : $t('shipping-disabled')
          "
          hide-details
          cy="returns-home-collection-enable"
        />
      </template>
      <template #sections>
        <div class="block-section">
          <div class="block-section">
            <we-editor
              v-model="payload.home_collection_return_details"
              :label="$t('description')"
            />
          </div>
        </div>
      </template>
    </we-block>
  </section>
</template>

<script>
import ReturnAddress from "@/views/Admin/Plugins/Returns/Edit/Address";
export default {
  components: {
    ReturnAddress
  },
  props: {
    value: Object
  },
  data() {
    return {
      payload: this.value,
      newAddressOpened: false,
      editAddressOpened: false,
      newAddress: {
        street: "",
        city: "",
        state: "",
        zipcode: "",
        block: "",
        note: "",
        number: ""
      },
      currentAddress: {},
      currentAddressIndex: 0
    };
  },
  watch: {
    payload: {
      handler() {
        this.handleInput();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleInput() {
      this.$emit("input", this.payload);
    },
    addAddress(event) {
      if (!this.payload.store_address) this.payload.store_address = [];

      this.payload.store_address = [...[event], ...this.payload.store_address];
    },
    editAddress(event) {
      if (!this.payload.store_address) this.payload.store_address = [];

      this.payload.store_address[this.currentAddressIndex] = event;
    },
    deleteAddress() {
      this.payload.store_address.splice(this.currentAddressIndex, 1);

      if (this.payload?.store_address?.length <= 0) {
        this.payload.store_address = null;
      }
    }
  }
};
</script>

<style></style>
