<i18n>
{
  "en": {
    "wepipe": "Returns requests",
    "wepipe-description": "Select the stages that you want to save every form request.",
    "pipeline-stages": "Stages to save the request",
    "domain": "Domain",
    "domain-description": "Type a domain of returns form and define the url that will be available for your customers."
  },
  "pt-BR": {
    "wepipe": "Requisições de devolução",
    "wepipe-description": "Selecione os estágios que você deseja salvar as novas requisições de devolução.",
    "pipeline-stages": "Estágios para salvar a requisição",
    "domain": "Domínio",
    "domain-description": "Digite um domínio do formulário de devolução para definir o link que será disponibilizado para seus clientes."
  }
}
</i18n>

<template>
  <section id="returns_configuration">
    <we-block :title="$t('wepipe')" :description="$t('wepipe-description')">
      <template #sections>
        <div class="block-section">
          <we-input
            type="global-multiselect"
            v-model="payload.initial_pipeline_stages"
            :items="allStagesWithPipelinesList"
            :label="$t('pipeline-stages')"
            is-required
            cy="returns-stages"
          />
        </div>
      </template>
    </we-block>
    <we-block :title="$t('domain')" :description="$t('domain-description')">
      <template #sections>
        <div class="block-section">
          <we-input
            type="text"
            v-model="payload.domain"
            :label="$t('domain')"
            is-required
            cy="returns-domain"
          />
        </div>
      </template>
    </we-block>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    value: Object
  },
  data() {
    return {
      payload: this.value
    };
  },
  watch: {
    payload: {
      handler() {
        this.handleInput();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    allStagesWithPipelinesList() {
      let items = [];

      this.pipelines.forEach(pipeline => {
        pipeline?.stages.forEach(stage => {
          items.push({
            text: `${stage.name} (${pipeline.name})`,
            value: stage.id
          });
        });
      });

      return items;
    }
  },
  methods: {
    ...mapActions(["pipelinesRequest"]),
    handleInput() {
      this.$emit("input", this.payload);
    }
  },
  mounted() {
    if (this.pipelines.length <= 0) {
      this.pipelinesRequest();
    }
  }
};
</script>

<style lang="scss"></style>
