<i18n>
{
  "en": {
    "chargeback-enabled": "Chargeback method enabled!",
    "chargeback-disabled": "Enable chargeback method",
    "refund": "Refund to original payment method",
    "refund-description": "Allow customers to receive the refund in the account that they paid for the items.",
    "description": "Description",
    "credit": "Get store credit",
    "credit-description": "Allow customers to receive a gift card with the value of the items that are in return request.",
    "credit-instant": "Enable instant credit",
    "credit-special": "Enable special offer modal",
    "maximum": "Maximum price",
    "percentage": "Percentage"
  },
  "pt-BR": {
    "chargeback-enabled": "Método de estorno habilitado!",
    "chargeback-disabled": "Habilitar método de estorno",
    "refund": "Estornar dinheiro",
    "refund-description": "Permite que os clientes recebam o dinheiro direto na conta do meio de pagamento que ele utilizou.",
    "description": "Descrição",
    "credit": "Crédito na loja",
    "credit-description": "Permitir que os clientes recebam um cupom ou vale presente na loja com o valor dos produtos que ele quer devolver.",
    "credit-instant": "Habilitar crédito instantâneo",
    "credit-special": "Habilitar oferta especial",
    "maximum": "Preço máximo para oferta",
    "percentage": "Porcentagem"
  }
}
</i18n>

<template>
  <section id="returns_chargebcak">
    <we-block
      :active="payload.is_cash_refund_enabled"
      class="toggle"
      :title="$t('refund')"
      :description="$t('refund-description')"
    >
      <template #top>
        <v-switch
          v-model="payload.is_cash_refund_enabled"
          :label="
            payload.is_cash_refund_enabled
              ? $t('chargeback-enabled')
              : $t('chargeback-disabled')
          "
          hide-details
          cy="returns-cash-refund-enable"
        />
      </template>
      <template #sections>
        <div class="block-section">
          <div class="block-section">
            <we-editor
              v-model="payload.cash_refund_details"
              :label="$t('description')"
            />
          </div>
        </div>
      </template>
    </we-block>
    <we-block
      :active="payload.is_credit_refund_enabled"
      class="toggle"
      :title="$t('credit')"
      :description="$t('credit-description')"
    >
      <template #top>
        <v-switch
          v-model="payload.is_credit_refund_enabled"
          :label="
            payload.is_credit_refund_enabled
              ? $t('chargeback-enabled')
              : $t('chargeback-disabled')
          "
          hide-details
          cy="returns-credit-refund-enable"
        />
      </template>
      <template #sections>
        <div class="block-section">
          <div class="block-section">
            <v-switch
              v-model="payload.is_instant_credit_enabled"
              :label="$t('credit-instant')"
              hide-details
            />
          </div>
          <div class="block-section">
            <v-switch
              v-model="payload.is_special_offer_enabled"
              :label="$t('credit-special')"
              hide-details
            />
          </div>
          <div
            class="block-section grid"
            v-if="payload.is_special_offer_enabled"
          >
            <we-input
              type="money"
              v-model="payload.special_offer_limit"
              :label="$t('maximum')"
            />
            <we-input
              type="number"
              v-model="payload.special_offer_percentage"
              :max="10"
              :label="$t('percentage')"
            />
          </div>
          <div class="block-section">
            <we-editor
              v-model="payload.credit_refund_details"
              :label="$t('description')"
            />
          </div>
        </div>
      </template>
    </we-block>
  </section>
</template>

<script>
export default {
  props: {
    value: Object
  },
  data() {
    return {
      payload: this.value
    };
  },
  watch: {
    payload: {
      handler() {
        this.handleInput();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleInput() {
      this.$emit("input", this.payload);
    }
  }
};
</script>
