<i18n>
{
  "en": {
    "address": "Address",
    "address-input-1": "Zip Code",
    "address-input-2": "Street",
    "address-input-3": "Number",
    "address-input-4": "District",
    "address-input-5": "Complement",
    "address-input-6": "City",
    "address-input-7": "State",
    "address-input-8": "Country",
    "delete-address": "Delete address",
    "update": "Update"
  },
  "pt-BR": {
    "address": "Endereço",
    "address-input-1": "CEP",
    "address-input-2": "Rua",
    "address-input-3": "Número",
    "address-input-4": "Bairro",
    "address-input-5": "Complemento",
    "address-input-6": "Cidade",
    "address-input-7": "Estado",
    "address-input-8": "País",
    "delete-address": "Deletar endereço",
    "update": "Atualizar"
  }
}
</i18n>

<template>
  <modal-action @close="close" :columns="2">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="globe-americas" />
            </div>
            {{ $t("address") }}
          </div>
          <div class="options">
            <we-icon-button
              v-if="deleteEnabled"
              class="big-mobile"
              @click="deleteAddress"
              :icon="['far', 'trash-alt']"
              :name="$t('delete-address')"
            />
            <we-icon-button class="big-mobile" icon="times" @click="close" />
          </div>
        </div>
        <div class="modal-body">
          <v-form class="fields" ref="form">
            <we-input
              v-model="payload.zipcode"
              type="text"
              :label="$t('address-input-1')"
              :mask="$root.$t('zipcode-mask')"
              @blur="getCep($event)"
              is-required
              cy="returns-zipcode"
            />
            <we-input
              v-model="payload.street"
              type="text"
              :label="$t('address-input-2')"
              :loading="cepLoading"
              is-required
            />
            <we-input
              v-model="payload.number"
              :mask="'######'"
              type="text"
              :label="$t('address-input-3')"
              is-required
              cy="returns-number"
            />
            <we-input
              v-model="payload.block"
              type="text"
              :label="$t('address-input-4')"
              :loading="cepLoading"
              is-required
            />
            <we-input
              v-model="payload.note"
              type="text"
              :label="$t('address-input-5')"
            />
            <we-input
              v-model="payload.city"
              type="text"
              :label="$t('address-input-6')"
              :loading="cepLoading"
              is-required
            />
            <we-input
              v-model="payload.state"
              type="text"
              :label="$t('address-input-7')"
              :loading="cepLoading"
              is-required
            />
            <we-input
              v-model="payload.country"
              type="text"
              :label="$t('address-input-8')"
              :loading="cepLoading"
              is-required
            />
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('update')"
            icon="check"
            :disabled="!payload.zipcode"
            @click="update"
          />
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    value: Object,
    deleteEnabled: Boolean
  },
  data() {
    return {
      payload: {},
      cepLoading: false
    };
  },
  methods: {
    ...mapActions(["cepRequest"]),
    update() {
      const validated = this.$refs.form.validate();

      if (validated) {
        this.$emit("input", this.payload);
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    async getCep(number) {
      if (number.length === 9) {
        this.cepLoading = true;
        const cep = await this.cepRequest(number.split("-").join(""));
        this.cepLoading = false;

        if (!cep.data) return;

        this.payload.street = cep.data.logradouro;
        this.payload.block = cep.data.bairro;
        this.payload.city = cep.data.cidade.nome;
        this.payload.state = cep.data.estado.sigla;
        this.payload.country = "Brasil";
      }
    },
    deleteAddress() {
      this.$emit("deleteAddress");
      this.close();
    }
  },
  mounted() {
    this.payload = { ...this.value };
  }
};
</script>

<style lang="scss" scoped>
.modal {
  .modal-body {
    min-height: 250px;
  }
}
</style>
