<i18n>
{
  "en": {
    "header": "Header",
    "header-description": "Customize header of return page.",
    "logo-description": "Upload your logo with transparent background and preferably SVG to maintain quality",
    "website": "Website url",
    "website-placeholder": "https://yourshop.com",
    "footer": "Footer",
    "footer-description": "Customize footer of return page.",
    "links": "Links",
    "terms": "Terms & Conditions url",
    "privacy": "Privacy Policy url",
    "help": "Help url",
    "ga": "Google Analytics",
    "ga-description": "Integrate with Google Analytics to check all returns portal information.",
    "favicon": "Favicon",
    "favicon-description": "Upload an image with a .svg or .png extension and with 96x96 size",
    "logo": "Logo",
    "logo-description": "Upload the logo with a transparent background and preferably in .svg to maintain the quality"
  },
  "pt-BR": {
    "header": "Cabeçalho",
    "header-description": "Customize o cabeçalho da página de devoluções.",
    "logo-description": "Faça upload da logo em SVG com fundo transparente para manter a qualidade",
    "website": "Url da loja",
    "website-placeholder": "https://sualoja.com.br",
    "footer": "Rodapé",
    "footer-description": "Customize o rodapé da página de devoluções.",
    "links": "Links",
    "terms": "Termos e condições",
    "privacy": "Políticas de privacidade",
    "help": "Central de atendimento",
    "ga": "Google Analytics",
    "ga-description": "Integre com Google Analytics e verifique todas as informações de acesso ao seu portal de returns.",
    "favicon": "Favicon",
    "favicon-description": "Faça upload de uma imagem com extensão .svg ou .png e com 96x96 de dimensão",
    "logo": "Logotipo",
    "logo-description": "Faça upload da logomarca com fundo transparente e de preferência em .svg para manter a qualidade"
  }
}
</i18n>

<template>
  <section id="returns_customization">
    <we-block :title="$t('header')" :description="$t('header-description')">
      <template #sections>
        <div class="block-section grid">
          <we-file-upload
            :title="$t('favicon')"
            :info="$t('favicon-description')"
            @change="sendFavicon"
            :file="payload.favicon"
            :loading="loadingFavicon"
            accept=".svg,.png"
          />
          <we-file-upload
            :title="$t('logo')"
            :info="$t('logo-description')"
            @change="sendLogo"
            :file="payload.brand"
            :loading="loadingLogo"
            accept=".pdf,.jpg,.jpeg,.png,.svg"
          />
        </div>
        <div class="block-section">
          <we-input
            v-model="payload.store_url"
            type="text"
            :label="$t('website')"
            :placeholder="$t('website-placeholder')"
          />
        </div>
      </template>
    </we-block>
    <we-block :title="$t('footer')" :description="$t('footer-description')">
      <template #sections>
        <div class="block-section mb-1">
          <we-input type="div" :placeholder="$t('links')" />
        </div>
        <div class="block-section grid">
          <we-input
            v-model="payload.terms_of_service"
            type="text"
            :label="$t('terms')"
          />
          <we-input
            v-model="payload.privacy_policies"
            type="text"
            :label="$t('privacy')"
          />
        </div>
        <div class="block-section">
          <we-input
            v-model="payload.help_center_url"
            type="text"
            :label="$t('help')"
          />
        </div>
      </template>
    </we-block>
    <we-block :title="$t('ga')" :description="$t('ga-description')">
      <template #sections>
        <div class="block-section">
          <we-input
            type="text"
            v-model="payload.google_analytics"
            :label="$t('ga')"
            mask="#########-#"
            prefix="UA-"
            placeholder="000000000-0"
          />
        </div>
      </template>
    </we-block>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    value: Object
  },
  data() {
    return {
      payload: this.value,
      loadingLogo: false,
      loadingFavicon: false
    };
  },
  watch: {
    payload: {
      handler() {
        this.handleInput();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions([
      "updateLogoReturnsFormRequest",
      "updateFaviconReturnsFormRequest"
    ]),
    handleInput() {
      this.$emit("input", this.payload);
    },
    async sendFavicon(file) {
      this.loadingFavicon = true;
      file.formId = this.$route.params.id;
      await this.updateFaviconReturnsFormRequest(file);
      this.updateImages();
      this.loadingFavicon = false;
    },
    async sendLogo(file) {
      this.loadingLogo = true;
      file.formId = this.$route.params.id;
      await this.updateLogoReturnsFormRequest(file);
      this.updateImages();
      this.loadingLogo = false;
    },
    updateImages() {
      this.$emit("updateImages");
    }
  }
};
</script>
