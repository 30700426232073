<i18n>
{
  "en": {
    "action": "Action",
    "return": "Return reasons",
    "return-description": "Add the return reasons that the customer can select for each item that he wants to return.",
    "return-reasons": "Reasons",
    "rules": "Rules",
    "rules-description": "Create rules to allow or disallow returns for specific kind of items.",
    "by-sku": "BY SKU",
    "by-sku-skus": "SKUs",
    "by-pricing": "BY PRICING",
    "by-pricing-price": "Price",
    "by-pricing-operator": "Operator",
    "by-country": "BY COUNTRY",
    "by-country-countries": "Countries"
  },
  "pt-BR": {
    "action": "Ação",
    "return": "Motivos de devolução",
    "return-description": "Adicione os motivos de devolução para o usuário selecionar para cada produto.",
    "return-reasons": "Motivos",
    "rules": "Regras",
    "rules-description": "Adicione regras para devolução de produtos específicos.",
    "by-sku": "Por SKU (Código do produto)",
    "by-sku-skus": "SKUs",
    "by-pricing": "Por preço",
    "by-pricing-price": "Preço",
    "by-pricing-operator": "Operador",
    "by-country": "Por país",
    "by-country-countries": "Países"
  }
}
</i18n>

<template>
  <section id="returns_items">
    <we-block :title="$t('return')" :description="$t('return-description')">
      <template #sections>
        <div class="block-section">
          <we-input
            v-model="payload.return_reasons"
            :placeholder="$t('return-reasons')"
            type="global-multiselect"
            :items="returnReasons"
          />
        </div>
      </template>
    </we-block>
    <we-block
      class="d-none"
      :title="$t('rules')"
      :description="$t('rules-description')"
    >
      <template #sections>
        <div class="block-section mb-2">
          <we-input type="div" :placeholder="$t('by-sku')" />
        </div>
        <div class="block-section grid">
          <we-input type="tags" :label="$t('by-sku-skus')" />
          <we-input
            type="select"
            :label="$t('action')"
            :items="['Not possible to return']"
          />
        </div>
        <div class="block-section mb-2">
          <we-input type="div" :placeholder="$t('by-pricing')" />
        </div>
        <div class="block-section grid">
          <div class="grid">
            <we-input type="money" :label="$t('by-pricing-price')" />
            <we-input
              type="select"
              :label="$t('by-pricing-operator')"
              :items="['=', '>=', '<=']"
            />
          </div>
          <we-input
            type="select"
            :label="$t('action')"
            :items="['Not possible to return']"
          />
        </div>
        <div class="block-section mb-2">
          <we-input type="div" :placeholder="$t('by-country')" />
        </div>
        <div class="block-section grid">
          <we-input
            type="select"
            :label="$t('by-country-countries')"
            :items="['Brazil', 'United States']"
          />
          <we-input
            type="select"
            :label="$t('action')"
            :items="['Not possible to return']"
          />
        </div>
      </template>
    </we-block>
  </section>
</template>

<script>
export default {
  props: {
    value: Object
  },
  data() {
    return {
      payload: this.value
    };
  },
  watch: {
    payload: {
      handler() {
        this.handleInput();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    returnReasons() {
      return this.$store.getters.getReturnReasons;
    }
  },
  methods: {
    handleInput() {
      this.$emit("input", this.payload);
    }
  }
};
</script>

<style lang="scss"></style>
