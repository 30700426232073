<template>
  <div id="lead">
    <lead-step v-if="step === 0" :loading="btnLoading" @nextStep="createLead" />
    <first-step
      v-if="step === 1"
      :loading="btnLoading"
      @nextStep="updateLead"
    />
    <second-step
      v-if="step === 2"
      :loading="btnLoading"
      @nextStep="updateLead"
    />
    <third-step
      v-if="step === 3"
      :loading="btnLoading"
      @nextStep="createCustomer"
    />
    <final-step v-if="step === 4" />
    <already-registered v-if="step === 5" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LeadStep from "@/views/CreateAccount/LeadStep.vue";
import FirstStep from "@/views/CreateAccount/FirstStep.vue";
import SecondStep from "@/views/CreateAccount/SecondStep.vue";
import ThirdStep from "@/views/CreateAccount/ThirdStep.vue";
import FinalStep from "@/views/CreateAccount/FinalStep.vue";
import AlreadyRegistered from "@/views/CreateAccount/AlreadyRegistered.vue";
export default {
  components: {
    LeadStep,
    FirstStep,
    SecondStep,
    ThirdStep,
    FinalStep,
    AlreadyRegistered
  },
  data() {
    return {
      step: 0,
      lead: {},
      btnLoading: false,
      enableRegisterFlag: true
    };
  },
  computed: {
    storeLead() {
      return this.$store.getters.getLead;
    }
  },
  mounted() {
    //this.$fb.track("AddToWishlist");
    if (this.storeLead.domain?.trim().length > 0) {
      this.step = 5;
    } else if (this.storeLead.email?.trim().length > 0) {
      this.lead = this.storeLead;
      this.step++;
    }
  },
  methods: {
    ...mapActions([
      "createLeadRequest",
      "updateLeadRequest",
      "createCustomerRequest"
    ]),
    async createLead(params) {
      this.btnLoading = true;
      const payload = {
        ...params
      };
      const response = await this.createLeadRequest(payload);

      if (response?.data?.domain?.trim().length > 0) {
        this.step = 5;
      } else if (response.data) {
        //this.$fb.track("Lead");
        this.lead = payload;
        this.step++;
      }
      this.btnLoading = false;
    },
    async updateLead(params) {
      this.btnLoading = true;
      const payload = {
        ...this.lead,
        ...params
      };

      const response = await this.updateLeadRequest(payload);

      if (response.data) {
        this.step++;
        this.lead = payload;
      }

      this.btnLoading = false;
    },
    async createCustomer(params) {
      this.btnLoading = true;
      const payload = {
        ...this.lead,
        ...params
      };
      await this.updateLeadRequest(payload);

      if (this.enableRegisterFlag) {
        this.enableRegisterFlag = false;
        const response = await this.createCustomerRequest(payload);
        if (response.data) {
          //this.$fb.track("CompleteRegistration");
          if (payload.total_employees !== "1 gratuito") {
            //this.$fb.track("Purchase", { value: 1.0, currency: "BRL" });
          }

          this.step++;
          this.lead = payload;
        }
      }
      this.btnLoading = false;
    }
  }
};
</script>

<style lang="scss">
#lead {
  position: relative;
  text-align: center;

  h2 {
    font-weight: $semibold;
    color: var(--text-1);
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 12px;
    text-align: center;
  }

  p {
    font-weight: $regular;
    color: var(--text-2);
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 36px;

    b {
      font-weight: $medium;
      color: var(--primary);
    }
  }

  .undraw {
    width: 100%;
    max-width: 138px;
    margin: 0 auto;
    margin-bottom: 36px;
  }

  .external-link {
    font-size: 22px;
    text-align: center;
    color: var(--primary);
    font-weight: $medium;
    display: block;

    &:hover {
      color: var(--text-1);
    }
  }

  .we-input .field .v-input {
    margin-bottom: 0;
  }

  .we-button {
    margin-bottom: 36px;
  }

  .obs {
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
  }

  .current-step {
    text-align: center;
    color: var(--text-2);
    font-size: 14px;
  }
}
</style>
